var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"control",class:{
    'has-icons-right': _vm.appearance === 'search',
    field: _vm.appearance === 'field',
    search: _vm.appearance === 'search',
    auth: _vm.appearance === 'auth',
    'is-danger': _vm.hasError,
  }},[_c('label',{directives:[{name:"show",rawName:"v-show",value:(_vm.label.length >= 1),expression:"label.length >= 1"}],staticClass:"label"},[_vm._v(_vm._s(_vm.label))]),(_vm.leftIcon)?_c('SvgIcon',{attrs:{"name":_vm.leftIcon}}):_vm._e(),_c('input',{staticClass:"input",class:{
      field: _vm.appearance === 'field',
      search: _vm.appearance === 'search',
      auth: _vm.appearance === 'auth',
      'has-icon-left': _vm.leftIcon,
    },style:(_vm.hasError ? 'border-color:red' : ''),attrs:{"id":_vm.id && _vm.id,"type":_vm.type,"placeholder":_vm.placeholder,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":($event) => _vm.$emit('input', $event.target.value),"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('enter')}}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasError),expression:"hasError"}],staticClass:"help is-danger"},[_vm._v(_vm._s(_vm.error))]),(_vm.appearance === 'search')?_c('span',{staticClass:"icon is-right"},[_c('i',{staticClass:"fa fa-magnifying-glass fa-lg"})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }