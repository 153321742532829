import { Loader } from "@googlemaps/js-api-loader";
import { ActionTypes as NotificationActions } from "@/vuex/modules/notifications-module/action-types";
import { NotificationType } from "@/vuex/modules/notifications-module/state";
import store from "@/store";

export class GoogleMapService {
  private loader!: Loader;
  private google!: any;
  private marker!: any;
  private map!: any;
  private coordinates!: { lat: number | null; lng: number | null };
  private address!: string;
  private autocomplete!: any;

  constructor() {
    this.loader = new Loader({
      apiKey: "AIzaSyCM_VsFfif8BZ9E_xTedGogyGEc9awzhi4",
      version: "weekly",
      libraries: ["places"],
    });
  }

  public setCoordinates(lat: number | null, lng: number | null): void {
    this.coordinates = { lat: lat, lng: lng };
  }

  public getCoordinates(): { lat: number | null; lng: number | null } {
    return this.coordinates;
  }

  public getAddress(): string {
    return this.address;
  }

  public async setGoogleMap(mapElement: HTMLElement | null): Promise<any> {
    try {
      this.google = await this.loader.load();

      this.map = this.getMap(mapElement);

      this.initAutocomplete();

      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => this.getCurrentPosition(position),
        (): void => {
          this.map = null;
        }
      );

      return this.map;
    } catch (exception: any) {
      console.log(exception);
    }
  }

  private getMap(mapElement: HTMLElement | null) {
    return new this.google.maps.Map(mapElement, {
      center: { lat: -34.397, lng: 150.644 },
      zoom: 12,
    });
  }

  private updateAddressInput(): void {
    const input = document.getElementById("address-input") as HTMLInputElement;
    if (input) {
      input.value = this.address || "";
    }
  }

  private initAutocomplete() {
    const input = document.getElementById("address-input") as HTMLInputElement;
    this.autocomplete = new this.google.maps.places.Autocomplete(input);

    this.autocomplete.addListener("place_changed", () => {
      const place = this.autocomplete.getPlace();
      if (place.geometry) {
        const pos = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };

        this.map.setCenter(pos);
        this.setMarkerOnMap(pos);

        this.coordinates = pos;
        this.address = place.formatted_address;
      }
    });
  }

  private getCurrentPosition(position: GeolocationPosition): void {
    const pos = {
      lat: !!this.coordinates.lat
        ? this.coordinates.lat
        : position.coords.latitude,
      lng: !!this.coordinates.lng
        ? this.coordinates.lng
        : position.coords.longitude,
    };

    this.map.setCenter(pos);
    this.setMarkerOnMap(pos);
    this.updateAddressInput();
  }

  private setMarkerOnMap(pos: { lat: number; lng: number }): void {
    if (this.marker) {
      this.marker.setMap(null);
    }
    this.marker = new this.google.maps.Marker({
      position: pos,
      map: this.map,
      draggable: true,
    });

    if (!this.coordinates.lat && !this.coordinates.lng) {
      this.setMarkerInfo();
    }

    this.marker.addListener("dragend", () => this.setMarkerInfo());
  }

  private setMarkerInfo() {
    this.setCoordinates(
      this.marker.getPosition().lat(),
      this.marker.getPosition().lng()
    );

    if (this.coordinates.lat && this.coordinates.lng) {
      const geocoder = new this.google.maps.Geocoder();
      const latLng = new this.google.maps.LatLng(
        this.coordinates.lat,
        this.coordinates.lng
      );

      geocoder.geocode(
        { location: latLng },
        (results: Array<any>, status: string) => {
          this.setAddress(results, status);
          this.updateAddressInput();
        }
      );
    }
  }

  private async setAddress(results: Array<any>, status: string): Promise<void> {
    if (status == "OK") {
      if (results[0]) {
        this.address = results[0].formatted_address;
      } else {
        await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
          text: "No results found",
          type: NotificationType.DANGER,
        });
      }
    } else {
      await store.dispatch(NotificationActions.PUSH_NOTIFICATION, {
        text: "Geocoder failed due to: " + status,
        type: NotificationType.DANGER,
      });
    }
  }
}
